.avatar{
    max-width: 200px;
    max-height: 200px;
    border-radius: 50%;
    margin-right:16px;
    padding: 4px;
    background-color: var(--white);
    border: 1px solid var(--light);
}

.image-loading {
    filter: blur(10px);
    clip-path: inset(0);
}

.image-loaded {
    filter: blur(0px);
    transition: filter 0.2s linear;
}