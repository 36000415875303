footer{
    max-width: 1024px;
    margin: 0 auto 16px auto;
    border-left: 1px solid var(--light);
    border-right: 1px solid var(--light);
}

footer section{
    border-radius: 0 0 8px 8px;
}

footer a{
    text-decoration: none;
    color: var(--light);
    padding: 4px 8px;
    margin: 8px;
}

footer a:hover{
    color: var(--white);
}

.flex-footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

/* small screen */
@media (max-width: 576px) {
    
}

/* medium screen */
@media (max-width: 768px) {
    .header .navbar{
        flex-direction: column;
        justify-content: center;
    }
    .logo{ margin: 0; }
    footer{ margin-bottom: 0; }
}

/* large screen */
@media (max-width: 992px) {
    
}

/* extra large screen */
@media (max-width: 1200px) {
    
}
