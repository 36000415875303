.flicker{
  font-family: 'Bebas';
  font-size: 4vw;
  letter-spacing: 12px;
  color: #ffe6ff;
  text-shadow: 0 0 6px #ffe6ff, 0 0 15px #ff65bd,
    -2px 1px 10px #ff65bd, 2px 1px 10px #ff65bd,
    0 -5px 20px #ff2483, 0 5px 30px #ff2483;
}

.blink-1 { animation: fl-shine 2s forwards, fl-blink 3s 2s infinite; }
.blink-2 { animation: fl-shine 2s forwards, fl-blink 10s 1s infinite; }
.blink-3 { animation: fl-shine 2s forwards, fl-broken 2s 6s infinite; }
/* blink [duration] [delay] */

@keyframes fl-blink {
  0%, 22%, 36%, 75% {
    color: #ffe6ff;
    text-shadow: 0 0 6px #ffe6ff, 0 0 15px #ff65bd,
      -2px 1px 10px #ff65bd, 2px 1px 10px #ff65bd,
      0 -5px 20px #ff2483, 0 5px 30px #ff2483;
  }
  28%, 33% {
    color: #ff65bd;
    text-shadow: none;
  }
  82%, 97% {
    color: #ff2483;
    text-shadow: none;
  }
}

@keyframes fl-broken {
  0%, 2% {
    color: #ffe6ff;
    text-shadow: 0 0 6px #ffe6ff, 0 0 15px #ff65bd,
      -2px 1px 10px #ff65bd, 2px 1px 10px #ff65bd,
      0 -5px 20px #ff2483, 0 5px 30px #ff2483;
  }
  4%, 9%{
    color: #ff65bd;
    text-shadow: none;
  }
  10%, 12% {
    color: #ffe6ff;
    text-shadow: 0 0 6px #ffe6ff, 0 0 15px #ff65bd,
      -2px 1px 10px #ff65bd, 2px 1px 10px #ff65bd,
      0 -5px 20px #ff2483, 0 5px 30px #ff2483;
  }
  13%, 20%{
    color: #ff65bd;
    text-shadow: none;
  }
  40%, 99% {
    color: #ff2483;
    text-shadow: none;
  }
}

@keyframes fl-shine {
  0% {
    color: #6b1839;
    text-shadow: none;
  }
  100% {
    color: #ffe6ff;
    text-shadow: 0 0 6px #ffe6ff, 0 0 15px #ff65bd,
      -2px 1px 10px #ff65bd, 2px 1px 1px #ff65bd,
      0 -5px 20px #ff2483, 0 5px 30px #ff2483;
  }
}
