.spinner-circle {
  margin: 8px auto;
  width: 50px;
  height: 50px;
  position: relative;
  border-radius: 50%;
}
.spinner {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border-right: 8px solid rgba(0, 0, 0, 0);
  border-top: 8px solid var(--me-blue);
  border-left: 8px solid var(--me-blue);
  border-bottom: 8px solid var(--me-blue);
  
  animation: rotate--spinner 0.8s infinite linear;
  -webkit-animation: rotate--spinner 0.8s infinite linear;
}

@keyframes rotate--spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  
}

@-webkit-keyframes rotate--spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}