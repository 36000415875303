#torn-raceway header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: -44px;
}
  
#torn-raceway header img{
  display: block;
  width: 100%;
  opacity: 0.3;
}
  
#torn-raceway header h1{
  position: absolute;
  color: var(--white);
  font-family: 'Bebas';
  font-size: 5vw; 
}

#torn-raceway header nav{
  position: relative;
  font-family: 'Bebas';
  bottom: 52px;
}

.mw-300{
  max-width: 300px;
}

#torn-raceway main{
  background-color: var(--p-grey);
  color: var(--grey);
  border-top: 2px solid var(--warning);
}

#torn-raceway nav{
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  list-style: none;
  flex-direction: row;
  margin: 0;
  padding: 0;
  font-size: 1.25em;
}

#torn-raceway nav a{
  color: var(--p-light);
  padding: 8px 24px 6px 24px;
  display: block;
}

#torn-raceway nav a.active{ color: var(--warning);}
#torn-raceway nav a:hover{cursor: pointer;}
#torn-raceway nav a:hover:not(.active){ background-color: rgba(0, 0, 0, 0.18); }

#torn-raceway section{
  padding: 16px;
}

#torn-raceway table{
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: #dee2e6;
  caption-side: bottom;
  border-collapse: collapse;
}

#torn-raceway tbody, td, tfoot, th, thead, tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

#torn-raceway th{
    text-align: inherit;
    text-align: -webkit-match-parent;
    color: var(--p-red)
}

#torn-raceway table > * > * > * {
  padding: .5rem .5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}
#torn-raceway table thead {
  border-bottom: 2px solid var(--p-sage);
}

#torn-raceway .striped > tbody > tr:nth-of-type(2n+1) > * {
  background-color: rgba(0, 0, 0, 0.08);
}

.icon-trophy{
  background-size: contain;
  width: 24px;
  height: 24px;
  display: block;
  margin-left: 8px;
}

.icon-first{ background-image: url(/public/img/ico/trophy_1.png); }
.icon-second{ background-image: url(/public/img/ico/trophy_2.png); }
.icon-third{ background-image: url(/public/img/ico/trophy_3.png); }

/* MEDIUM SCREEN */
@media (max-width: 768px) {
  #torn-raceway header h1{ font-size: 6vw; }
}

/* SMALL SCREEN */
@media (max-width: 576px) {
  #torn-raceway header h1{ top: 32px; }
}

