.chart-container{
    height: 500px;
    background-color: var(--p-black);
    color: #472f2f;
    border-radius: 16px;
}

#torn .intro{
    color: var(--p-sage);
    font-size: 1.1em;
}