.rounded{
    border-radius: 8px;
}

.deck { 
    display: flex; 
    flex-flow: row wrap;
    box-sizing: border-box;
    margin-left: -16px;
    margin-right: 4px;
}
  
.card-wrap {
    flex: 0 0 50%;
    display: flex;
    padding: 8px; /* gutter width */
    box-sizing: border-box;
}

.card {
    box-shadow: 2px 2px 8px rgba(0,0,0,0.1);
    flex: 0 0 100%;
    margin: 8px;
    border-radius: 6px;
    border: 1px solid var(--light);
    background-color: var(--white);
    display: flex;
    flex-direction: column;
}

.card h2{
    padding: 8px 16px 0 16px;
    border-radius: 6px 6px 0 0 ;
    margin: 0;
}

.card-body{
    padding: 4px 16px;
    color: var(--grey);
    flex: 1 1 auto;
}

.card-footer{
    background-color: var(--light);
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--grey);
}

.pill{
    font-size: 0.75em;
    background-color: var(--grey);
    color: var(--white);
    margin: 2px;
    padding: 2px 8px;
    border-radius: 0.8em;
}

/* small screen */
@media (max-width: 576px) {
    
}

/* medium screen */
@media (max-width: 768px) {
    .card-wrap{ flex: 0 0 100%; }
}