header{
    background-color: var(--dark) !important;
    color: var(--white);
}

.navbar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

header nav{
    display: flex;
}

.navbar ul{
    display: flex;
    list-style: none;
    flex-direction: row;
    margin: 8px 0;
}

.navbar li a{
    display: block;
    padding: 8px 16px;
    color: var(--light);
    text-decoration: none;
    transition: border-top 0.2s ease-in-out, border-bottom 0.2s ease-in-out;
}

.navbar li .active {
    color: var(--me-blue);
}

.navbar li a:hover:not(.active) {
    color: var(--white);
}

.navbar button{
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: var(--white);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 4px 8px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    height: 100%;
}
  
.navbar li button img{
    vertical-align: middle;
}

.logo{
    font-size: 2em;
    font-family: 'Valera';
    text-decoration: none;
    color: var(--white) !important;
    margin-right: 16px;
    padding: 16px 0
}

/* small screen */
@media (max-width: 576px) {
    
}

/* medium screen */
@media (max-width: 768px) {
    header .navbar{
        flex-direction: column;
        justify-content: center;
    }
    .logo{
        margin: 0;
    }
}

/* large screen */
@media (max-width: 992px) {
    
}

/* extra large screen */
@media (max-width: 1200px) {
    
}
