/*  INICIO CUSTOM CSS  */
@font-face { font-family: 'Valera'; src: url('./fonts/VarelaRound-Regular.ttf'); }
@font-face { font-family: 'Cabin Sketch'; src: url('./fonts/CabinSketch-Regular.ttf'); }
@font-face { font-family: 'Cabin Bold'; src: url('./fonts/CabinSketch-Bold.ttf'); }
@font-face { font-family: 'Bebas'; src: url('./fonts/BebasNeue-Regular.ttf'); }
@font-face { font-family: 'Shadows'; src: url("./fonts/ShadowsIntoLight-Regular.ttf"); }
@font-face { font-family: 'Bad Script'; src: url("./fonts/BadScript-Regular.ttf"); }

:root {
  --me-blue: #01b4b8;
  --primary: #0d6efd;
  --warning: #ffc107;
  --danger: #dc3545;
  --success: #198754;
  --info: #0dcaf0;
  --body-text: #212529;

  --blue: #33b7e2;
  --white: #ffffff;
  --purple: #5e62b0;
  --pink: #dc307c;
  --dark: #212529;
  --light: #E3E3E4;
  --grey: #6c757d;
  --black: #000000;
  --darker: rgb(20, 23, 24);

  --font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;

  --p-black: #131515;
  --p-grey: #2B2C28;
  --p-red: #C05746;
  --p-light: #FCECC9;
  --p-sage: #B8B08D;

  --p-raceway: #01b4b8;
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body{
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.bg-container{
  background: var(--light) url(../public/img/patterns/ignasi.png) repeat;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  color: var(--body-text);
  overflow: hidden;
  min-height: 100vh;
}

h1{ font-family: 'Cabin Bold'; }
h2, h3, h4{ font-family: 'Bebas'; }
h2{ color: var(--primary); }

h1{ font-size: 2.5em; }
h2{ font-size: 2em; }
h3{ font-size: 1.75em; }
h4{ font-size: 1.5em; }
h5{ font-size: 1.25em; }
h6{ font-size: 1.1em; }

h1, h2, h3, h4, h5, h6{ font-weight: 500; margin-bottom: 8px; }

p{ margin-bottom: 16px; }
p:last-child{ margin-bottom: 0;}

a{
  text-decoration: none;
  color: var(--primary);
}
a:hover{
  text-decoration: none;
}

ul, ol{
  margin-bottom: 8px;
  padding-left: 16px;
}

hr{
  background-color: var(--light);
  height: 1px;
  border: 0;
  opacity: 0.25;
  margin: 16px 0;
}

.container{
  max-width: 1024px;
  margin: 0 auto;
  padding: 8px 32px;
}

.main{
  max-width: 1024px;
  margin: 0 auto;
  background-color: var(--white);
  border-left: 1px solid var(--light);
  border-right: 1px solid var(--light);
}

.fullwidth{
  width: 100%;
}

.main section{ padding: 8px 32px; overflow: hidden; }
.main section:first-child{ padding-top: 16px; }
.main section:last-child{ padding-bottom: 16px; }

.font-sketch{ font-family: 'Cabin Sketch'; }
.font-valera{ font-family: 'Valera'; }

.bg-white{ background-color: var(--white); color: var(--black); }
.bg-secondary{ background-color: var(--grey); color: var(--white); }
.bg-dark{ background-color: var(--dark); color: var(--white); }
.bg-light{ background-color: var(--light); color: var(--black); }
.bg-info{ background-color: var(--info); color: var(--black); }

.bg-quartz{ 
  background-image: linear-gradient(90deg, var(--blue), var(--purple), var(--pink)); 
  color: var(--white);
}

.me-blue{ color: var(--me-blue); }
.me-bg-blue{ background-color: var(--me-blue); }

.text-body{ color: var(--body-text); }
.text-primary{ color: var(--primary); }
.text-secondary{ color: var(--grey); }
.text-white{ color: var(--white); }
.text-danger{ color: var(--danger); }
.text-dark{ color: var(--dark); }

.alert-danger{
  color: #842029;
  background-color: #f8d7da;
  border: 1px solid #f5c2c7;
  padding: 8px;
  border-radius: 4px;
}

.flex{
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.flex-center{ justify-content: center; }
.flex-between{ justify-content: space-between; }
.flex-right{ justify-content: right; }

.flex-md{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-items-top{ align-items: flex-start!important; }
.flex-items-stretch{ align-items: stretch!important; }

.btn{
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-warning { color: #000; background-color: #ffc107; border-color: #ffc107; }
.btn-warning:hover { background-color: #ffca2c; border-color: #ffc720 }

.btn-border-warning { border-color: #ffc107}
.btn-border-warning:hover { background-color: #ffc107; }

.btn-primary { color: #fff; background-color: #0d6efd; border-color: #0d6efd; }
.btn-primary:hover { background-color: #0b5ed7; border-color: #0a58ca }

.btn-secondary { color: #fff; background-color: #6c757d; border-color: #6c757d; }
.btn-secondary:hover { background-color: #5c636a; border-color: #565e64 }

.btn-success { color: #fff; background-color: #198754; border-color: #198754; }
.btn-success:hover { background-color: #157347; border-color: #146c43 }

.btn-info { color: #000; background-color: #0dcaf0; border-color: #0dcaf0; }
.btn-info:hover { background-color: #31d2f2; border-color: #25cff2; }

.btn-danger { color: #fff; background-color: #dc3545; border-color: #dc3545; }
.btn-danger:hover { background-color: #bb2d3b; border-color: #b02a37 }

.btn-sm{ font-size: 0.75em; padding: 2px 6px; }

.btn-link{
  padding: 4px 8px;
  margin: 2px 4px;
}

.btn-link img:hover{
  filter: drop-shadow(1px 1px 2px var(--dark));
}

.summary{ font-size: 1.55em; }

.skill-card{
  border: 1px solid var(--light);
  padding: 8px 16px;
  border-radius: 4px;
  overflow: hidden;
  margin: 4px 4px;
}

.skill-card img{
  width: 64px;
  float: left;
  margin-right: 8px;
}

.skill-card .badge{ float:left; }

.badge{
  background-color: var(--grey);
  color: var(--white);
  padding: 4px 8px;
  margin-right: 4px;
  margin-bottom: 4px;
}

.row{ 
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.col-6{ width: 50%; flex: 0 0 auto;}

.text-center{ text-align: center; }
.text-right{ text-align: right; }

.text-sm{ font-size: 0.75em; }

.show-md{ display: none; }

.p-0{ padding: 0!important; }
.p-sm{ padding: 8px!important; }
.p-md{ padding: 16px!important; }

.pt-sm{ padding-top: 8px!important; }
.pt-md{ padding-top: 16px!important; }

.pb-0{ padding-bottom: 0!important; }
.pb-xs{ padding-bottom: 4px!important; }
.pb-sm{ padding-bottom: 8px!important; }
.pt-md{ padding-bottom: 16px!important; }
.pt-lg{ padding-bottom: 32px!important; }

.py-0{ padding-top: 0!important; padding-bottom: 0!important; }
.py-sm{ padding-top: 8px!important; padding-bottom: 8px!important; }
.py-md{ padding-top: 16px!important; padding-bottom: 16px!important; }
.py-lg{ padding-top: 32px!important; padding-bottom: 32px!important; }

.px-0{ padding-left: 0!important; padding-right: 0!important; }
.px-sm{ padding-left: 8px!important; padding-right: 8px!important; }
.px-md{ padding-left: 16px!important; padding-right: 16px!important; }
.px-lg{ padding-left: 32px!important; padding-right: 32px!important; }

.m-0{ margin: 0!important; }
.m-sm{ margin: 8px!important; }
.m-md{ margin: 16px!important; }
.m-lg{ margin: 32px!important; }

.mx-0{ margin-left: 0!important; margin-right: 0!important; }
.mx-sm{ margin-left: 8px!important; margin-right: 8px!important; }
.mx-md{ margin-left: 16px!important; margin-right: 16px!important; }
.mx-lg{ margin-left: 32px!important; margin-right: 32px!important; }

.my-0{ margin-top: 0!important; margin-bottom: 0!important; }
.my-sm{ margin-top: 8px!important; margin-bottom: 8px!important; }
.my-md{ margin-top: 16px!important; margin-bottom: 16px!important; }
.my-lg{ margin-top: 32px!important; margin-bottom: 32px!important; }

.mb-0{ margin-bottom: 0!important; }
.mb-xs{ margin-bottom: 4px!important; }
.mb-sm{ margin-bottom: 8px!important; }
.mb-md{ margin-bottom: 16px!important; }
.mb-lg{ margin-bottom: 32px!important; }

.mt-0{ margin-top: 0!important; }
.mt-sm{ margin-top: 8px!important; }
.mt-md{ margin-top: 16px!important; }
.mt-lg{ margin-top: 32px!important; }

.ml-0{ margin-left: 0!important; }
.ml-sm{ margin-left: 8px!important; }
.ml-md{ margin-left: 16px!important; }
.ml-lg{ margin-left: 32px!important; }

.mr-0{ margin-right: 0!important; }
.mr-sm{ margin-right: 8px!important; }
.mr-md{ margin-right: 16px!important; }
.mr-lg{ margin-right: 32px!important; }

.float-right{ float: right; }

.text-md-right{ text-align: right; }
.float-md-right{ float: right; }

.card-list{
  list-style: none;
  display: flex;
  flex-direction: column;
}

.card-list li{
  padding: 4px;
  border-top: 1px solid var(--light);
}
.card-list li:first-child{
  padding: 4px;
  border-top: 0;
}

.thumbnail{
  padding: 2px;
  background-color: var(--white);
  border-radius: 4px;
  border: 1px solid var(--light);
  margin: 4px;
}

.shadow{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3) !important;
}

/* SMALL SCREEN */
@media (max-width: 576px) {
  .text-sm-justify{ text-align: justify; }
  .project-header h1{ font-size: 5vw; }
}

/* MEDIUM SCREEN */
@media (max-width: 768px) {
  .content-md-center{ justify-content: center !important; }
  .flex-md{ 
    flex-direction: column; 
    align-items: center; 
  }
  .avatar{
    margin-right: 0;
    margin-bottom: 8px;
  }
  .col-6{ width: 100%; }
  .show-md{ display: block!important; }

  .text-md-right{ text-align: left; }
  .float-md-right{ float: left; }
}


/*  FIN CUSTOM CSS  */