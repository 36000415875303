.track{
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 16px;
    border-radius: 8px;
    background-color: var(--p-black);
}

.track h6{
    font-size: 0.85em;
    font-weight: bold;
    text-align: center;
}

.track-car{
    margin: 0px 4px;
}

.track-car .best{
    margin: 0 8px;
    color: var(--p-sage)
}

.track-car .total{
    color: var(--light);
    background-color: var(--p-grey);
    font-size: 0.8em;
    padding: 0 8px;
    margin-left: 8px;
    border-radius: 4px;
    cursor: pointer;
}

.overall{ color: var(--p-red); }

.border-b{ border-bottom: 1px solid var(--p-grey); }


