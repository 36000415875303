.titled-img{
    position: relative;
    text-align: center;
    width: 100%;
    background-color: var(--light);
    color: #252323;
  }
  
  .titled-img img{
    display: block;
    width: 100%;
    height: auto;
  }
  
  .titled-img h4{
    position: absolute;
    bottom: 8px;
    left: 0;
    padding: 2px 16px;
    color: var(--white);
    background-color: rgba(0, 0, 0, 0.6);
  }
  