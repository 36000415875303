.input-group{
  display: flex;
  justify-content: center;
}

.input-group label{
  color: var(--light);
}

.input-group input{
  background-color: var(--light);
  color: var(--black);
}

.bg-red{ 
  background-color: var(--p-red); 
  color: var(--p-light);
}

.input-group > *{
  font-weight: 400;
  line-height: 1.5;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 4px 8px;
  font-size: 1.2em;
}


.input-group input::placeholder {
  color: var(--p-grey);
}

.input-group > :first-child{
  border-radius: 4px 0 0 4px;
}

.input-group > :not(:first-child){
  /*margin-left: -1px;*/
}

.input-group > :last-child{
  border-radius: 0 4px 4px 0;
}
