#torn-bazaar{
  min-height: 100vh;
}

#torn-bazaar header{
  position: relative;
  text-align: left;
  color: white;
}

#torn-bazaar header h3{ margin: 0; font-size: 2vw; }
#torn-bazaar header h1{ 
  margin-top: -16px;
  font-size: 5vw; 
  font-family: 'Shadows'; 
}

#torn-bazaar header img{
  display: block;
  width: 100%;
  opacity: 0.6;
}

#torn-bazaar .title{
  position: absolute;
  bottom: 0px;
  left: 32px;
}


@media (max-width: 768px) {
  #torn-bazaar header h1{ font-size: 8vw; margin-top: -8px; }
  #torn-bazaar header h4{ font-size: 5vw; }
}